import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

// import ShamalLogo from '../assets/img/logo.png'

export default function SeoHeader({ title, children }) {
  const SeoTitle = title ? `${title} | Shamal Workwear` : 'Shamal Workwear'
  const ShamalUrl = 'https://shamal-dev.netlify.com'
  const ShamalDescription = 'Specialized work wear for extreme hot climates. Built for workers.'
  const ShamalLogo = `${ShamalUrl}/logo.png`

  return (
    <Helmet defer={false}>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{SeoTitle}</title>
      <html lang="en" />

      <meta name="description" content={ShamalDescription} />
      <meta property="og:title" content={SeoTitle} />
      <meta name="robots" content="noindex, nofollow" />

      <meta property="og:description" content={ShamalDescription} />
      <meta property="og:url" content={ShamalUrl} />
      <meta property="og:site_name" content="Shamal Workwear" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image" content={ShamalLogo} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={ShamalUrl} />
      <meta name="twitter:title" content={SeoTitle} />
      <meta name="twitter:description" content={ShamalDescription} />
      <meta name="twitter:image" content={ShamalLogo} />

      {children}
      {/* TODO: https://htmlhead.dev/#recommended-minimum */}
    </Helmet>
  )
}

SeoHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}
